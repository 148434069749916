<template>
    <section class="page-wrap">
        <div class="content-part">
            <div class="container">
                <DetailContent />
            </div>
        </div>
    </section>
</template>
<script>
import DetailContent from './partials/DetailContent.vue';

export default {
    name: "PropertyDetail",
    components: { DetailContent }
}
</script>
<style src="../../assets/css/property.css"></style>