<template>
    <div class="col-xl-4">
        <div v-if="propertyList.length > 0" class="similar-properties">
            <div class="section-title">
                <h2>Similer Properties</h2>
            </div>
            <div class="similar-list">
                <ul>
                    <li v-for="(item,i) in propertyList" :key="i">
                        <router-link :to="{ name: 'PropertyDetail', params: {slug:item.slug, id:item.id } }">
                            <div class="property-listView display-card p-0">
                                <div class="propertyImage">
                                    <img :src="item.image_path" class="img-fluid">
                                </div>
                                <div class="propertyList-content property-content">
                                    <h3>{{ item.title }}</h3>
                                    <span class="listLocation"> <span class="icon-location"></span> {{ item.address }}</span>
                                    <ul>
                                        <li v-if="item.purpose == 'rent'" class="pricing">
                                            <span>{{ item.currency }}: {{ item.price }}/{{ item.price_for }}</span>
                                        </li>
                                        <li v-else class="pricing">
                                            <span>{{ item.currency }}: {{ item.price }}</span>
                                        </li>
                                        <li>{{ item.approx_area }}</li>
                                    </ul>
                                </div>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SimilerList',
    props:{
        properties:{
            required:true,
        }
    },
    data(){
        return {
            propertyList:[],
        };
    },
    watch:{
        properties(value){
            let app = this;
            app.propertyList = value;
        }
    }
}
</script>